<template>
  <div class="layout-topbar">
    <img
      v-if="empresa.logo"
      :src="'data:image/png;base64, ' + empresa.logo"
      width="100"
      height="60"
      alt="Logo.jpg"
    />
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>
    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <div class="centro-topbar">
      <span class="estilo-fecha"> {{ currentDateTime }}</span>
    </div>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <Menubar :model="nestedMenuitems">
        <template #start>
          <p><strong>Usuario: </strong>{{ user.name }}</p>
        </template>
        <template #end>
          <button
            class="p-link layout-topbar-button"
            @click="openNuevo"
            v-tooltip.bottom="'Salir'"
          >
            <i class="pi pi-fw pi-sign-in"></i>
          </button>
        </template>
      </Menubar>
    </ul>
  </div>
  <Dialog
    v-model:visible="salirDialog"
    :style="{ width: '450px' }"
    header="Cerrar Sesión"
    :modal="true"
    class="p-fluid"
  >
    <h4><strong>Est&aacute;s seguro de Cerrar Sesión?</strong></h4>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-outlined p-button-success mr-2 mb-2 p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="Cerrar Sesión"
        icon="pi pi-check"
        class="p-button-outlined p-button-danger mr-2 mb-2 p-button-lg"
        @click="cerrarSession()"
      />
    </template>
  </Dialog>
</template>

<script>
import { useAuth } from "@/stores";
import EmpresaService from "@/service/EmpresaService";

export default {
  emits: ["menu-toggle"],
  data() {
    return {
      empresa: {},
      user: {},
      fechaActual: new Date(),
      salirDialog: false,
      nestedMenuitems: [
        {
          label: "Perfil",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "Ver Perfil",
              icon: "pi pi-user",
              to: "/usuario-detalle",
            },
          ],
        },
      ],
      currentDateTime: "",
    };
  },
  auth: null,
  empresaService: null,
  beforeCreate() {
    this.empresaService = new EmpresaService();
    this.empresaService.getFirstEmpresa().then((response) => {
      this.empresa = response.data;
    });
  },
  created() {
    this.auth = useAuth();
    this.updateDateTime();
    setInterval(this.updateDateTime, 1000);
  },
  mounted() {
    this.user = { ...this.auth.user };
  },
  methods: {
    updateDateTime() {
      const now = new Date();
      this.currentDateTime = now.toLocaleString(); // Actualiza la propiedad con la fecha y hora actual
    },
    openNuevo() {
      this.salirDialog = true;
    },
    ocultarDialog() {
      this.salirDialog = false;
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    } /* 
				topbarImage() {
					return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
				}, */,
    cerrarSession() {
      return this.auth.userLogout();
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
<style scoped>
.centro-topbar {
  display: flex;
  justify-content: right;
  align-items: center;
  flex: 1;
}
.estilo-fecha {
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 5px;
  margin-right: 1px;
}
</style>
